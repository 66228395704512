import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AngularFireDatabase } from 'angularfire2/database';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public brotherInfo;

  constructor(
    public storage: Storage,
    public afd: AngularFireDatabase) { }

    getUserSiteInfo(email, userInfo){
      this.afd.list('/brothers/').valueChanges()
      .subscribe(data => {
        this.brotherInfo = data;
        this.brotherInfo.forEach((element, i) => {
          if (element.Email == email){
            userInfo.index = i,
            userInfo.firstName = element["First Name "],
            userInfo.lastName = element["Last Name"],
            userInfo.address = element["Home Address"],
            userInfo.chapterOfInit = element["Chapter Initiation"],
            userInfo.yearOfInit = element["Year of Initiation"],
            userInfo.profession = element.Profession,
            userInfo.phone = element.Mobile,
            userInfo.lifeMember = element["Life Member"],
            userInfo.membershipNum = element["Membership Number"],
            userInfo.isAdmin = element.isAdmin
          };
          localStorage.setItem('userInfo',JSON.stringify(userInfo));
          if(userInfo.isAdmin == 'yes'){
            console.log('')
            $('.directoryLink').removeClass('hide');
            $('.createPoll').removeClass('hide');
            $(document).ready(function(){
              $('.editPoll').removeClass('hide');
            })
          }else{
            $('.directoryLink').addClass('hide');
            $('.createPoll').addClass('hide');
            $(document).ready(function(){
            $('.editPoll').addClass('hide');
            });
          }
        });
      });
      return userInfo;
    }

  loginUpdateUserInfo(userInfo){
    userInfo = JSON.parse(localStorage.getItem('userInfo'));
          $('.directoryLink').removeClass('hide');
          $('.profileLink').removeClass('hide');
          $('.logoutLink').removeClass('hide');
  }

  logoutUpdateUserInfo(userInfo){
    userInfo = JSON.parse(localStorage.getItem('userInfo'));
          $('.directoryLink').addClass('hide');
          $('.profileLink').addClass('hide');
          $('.logoutLink').addClass('hide');
    }
}
